.memberPill {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  padding: 2px;
  text-align: center;
  background-image: linear-gradient(#6206bf, #38a3a5);
  margin: 0px -5px 0px -5px;
  z-index: 100;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
}

.memberPillInner {
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
}

.memberOverviewPill {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 48px;
  padding: 1px;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  text-align: center;
  background-image: linear-gradient(#6206bf, #38a3a5);
  margin: 0px -2px 0px -2px;
  z-index: 100;
  align-items: center;
  justify-content: center;
}

.memberOverviewPillInner {
  background-color: #fff;
  border-radius: 48px;
  padding: 10px;
}
