.text-warning {
    color: #d61111;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
}

a {
    color: #274FF5;
    cursor: pointer;
}

h3 {
    margin-top: 0;
    font-weight: 400;
    font-family: "Causten Regular";
}

.phoneNumberInput {
    border: 0;
    padding-left: 16px;
    font-family: "Causten Regular";
    height: 25px;
  }
  
  .phoneNumberInput:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
  
  .phoneNumberContainer {
    margin-bottom: 15px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: #6206bf;
    height: 50px;
    margin-top: 17px;
  }