.menuButton {
  color: #12121f;
  font-family: "Causten Round";
  font-size: 12.2px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.sideBar {
  /* padding: 10px; */
  background-color: #fff;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  max-width: 200px;
  min-width: 200px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-top: 5rem;

}
.sideBarButton {
  color: #6206bf;
  padding: 1rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  transition: background 0.5s ease, color 0.5s ease;
}
.sideBarButton:hover {
  background: linear-gradient(119deg, #6206bf 32.77%, #38a3a5 92.35%);
  color: #fff;
  cursor: pointer;
}
.sideBarButtonActive {
  background: linear-gradient(119deg, #6206bf 32.77%, #38a3a5 92.35%);
  color: #fff;
  cursor: pointer;
}
.divider {
  height: 1px;
  /* background-color: #6206bf; */
  width: 100%;
  margin-top: 1rem;
}
.linkPreviewThumbnail {
  height: 300px;
  width: auto;
  background-size: contain;
  justify-self: center;
}
.domainText {
  color: #6206bf;
}
.filterContainer{
  margin-top: 1rem;
}