.backgroundGradient {
  min-height: 100vh;
  background: linear-gradient(147.59deg, #6206bf 17.96%, #38a3a5 89.33%);
  padding: 5vw 15vw 5vw 15vw;
  margin: 0;
}
.noScrollbackgroundGradient {
  min-height: 100svh;
  max-height: 100svh;
  background: linear-gradient(147.59deg, #6206bf 17.96%, #38a3a5 89.33%);
  padding: 3vw 20vw 0vw 20vw;
  margin: 0;
}
.mobileOnly {
  display: none;
}

.innerBox {
  border-radius: 15px;
  padding: 50px;
  background-color: #fff;
}
.GCinnerBox {
  border-radius: 15px;
  padding: 50px 50px 20px 50px;
  background-color: #fff;
  flex: 1;
  height: 79svh !important;

}

@media only screen and (max-width: 600px) {
  .backgroundGradient {
    padding: 0px 0px 0px 0px;
    padding-top: 10vh;
    height: 100%;
  }
  .noScrollbackgroundGradient {
    padding: 0px 0px 0px 0px;
    /* padding-top: 10vh; */
    height: 100%;
  }

  .innerBox {
    min-height: 85vh;
    padding: 10px;
    margin: 0px;
  }

  .hideMobile {
    display: none;
  }

  .mobileOnly {
    display: inherit;
  }
  .GCinnerBox {
    border-radius: 0px;
    padding: 16px;
    /* background-color: red; */
    overflow: hidden;
    height: calc(100svh - 80px) !important;
  }
}
