.optionButton {
  flex: 1;
}

.mobileOnly {
  display: none;
}

.logoImg {
  width: 25vw;
  max-width: 250px;
  max-height: 80px;
}

a {
  text-decoration: none;
}

body,
html,
.root {
  padding: 0px;
  margin: 0px;
}

.backgroundGradientOF {
  min-height: 100vh;
  background: linear-gradient(130.42deg, #6206bf 18.85%, #38a3a5 103.31%);
  padding: 5vw 15vw 5vw 15vw;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .backgroundGradientOF {
    padding: 1vh 0px 0px 0px !important;
    margin: 0px;
    height: 100%;
    width: 100vw !important;
    background: white !important;
  }

  .innerBox {
    min-height: 90vh;
    padding: 12px !important;
    margin: 0px;
  }

  .logoImg {
    max-height: 100px;
  }
}

.groupThumbnail {
  max-width: 80%;
  border-radius: 25px;
}

.avatarPhotoContainer {
  background: #d9d9d9;
  width: 10vw;
  height: 10vw;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  margin-top: -5vw;
  align-items: center;
  justify-content: center;
}

.avatarPhoto {
  border-radius: 50%;
  width: 100%;
}
