h3 > span {
  font-family: "Causten Bold";
}

.phoneNumberInput {
  border: 0;
  padding-left: 16px;
  font-family: "Causten Regular";
  height: 25px;
}

.phoneNumberInput:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.phoneNumberContainer {
  margin-bottom: 15px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #6206bf;
  height: 50px;
  margin-top: 17px;
}

.formContainer {
  width: 50%;
}

@media only screen and (max-width: 600px) {
  .formContainer {
    width: 100%;
  }
}
