.composer-container {
  padding-top: 10px;
}
.composer-option-container {
  margin-right: 11px !important;
  height: 36px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0px 12px;
  background: #ffffff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.composer-option-container:hover {
  scale: 1.02;
}
.composer-option-icon {
  padding-right: 20px;
}

@media only screen and (max-width: 600px) {
  .composer-option-icon {
    padding-right: 10px;
  }
}
