.media-options {
  margin-top: 16px !important;
}
.media-option {
  width: 100%;
  height: 36px !important;
  padding: 0px 12px;
  background: #ffffff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin-bottom: 1rem;
  cursor: pointer;
}
.media-option-text {
  margin-left: 20px;
}
