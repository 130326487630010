.container {
  background-color: red;
  padding: 16px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 5px 0px 15px 0px rgba(56, 163, 165, 0.4),
    0px 5px 10px 0px rgba(98, 6, 191, 0.4);
  min-height: 100px;
  width: 100%;
  margin: 2rem 0px;
}

.postImage {
  height: 300px;
  width: 300px;
  background-size: cover;
  align-self: center;
  z-index: 1;

}

.postImageBlur {
  height: 100%;
  width: 100%;
  background-size: cover;
  /* Standard filter property */
  filter: blur(3px);

  /* Vendor prefixes for Webkit, Mozilla, and Opera browsers */
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);

  /* IE filter for older versions (less performant) */
  -ms-filter: blur(3px);
  position: absolute;
}

.profilePicture {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.userName {
  padding-left: 1rem;
  color: #6206bf;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: center;
}

.createdAt {
  padding-left: 0.5rem;
  color: #49454F;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-self: center;
  padding-top: 2px
}

.messageContent {
  margin: 10px 0px;
}

.menuButton {
  color: #000000;
}

.menuItem {
  color: #6206bf;
  font-size: 1rem;
}

.linkPreviewThumbnail {
  height: 300px;
  width: 100%;
  background-size: contain;
  justify-self: center;
}

.domainText {
  color: #6206bf;
}

.readMore {
  color: #6206bf;
  cursor: pointer;
  height: auto;
  transition: all 0.5s ease-in;
}

.linkDescription {
  font-weight: bold;
}