.poll_wrapper {
  padding: 0px 0px 0px 30px;
}

.poll_percentage {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 1rem;
  /* line-height: 20px; */
  text-align: right;
  color: #2d2f33;
}

.poll_Option_Message {
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  /* line-height: 20px; */
  color: #2d2f33;
}
.poll_checkbox {
  background-color: #6206bf;
  border-radius: 50%;
}
.progress_bar {
  background-color: #6206bf;
  border-radius: 20px;
  height: 0.35rem;
  width: 100%;
}
.checkbox {
  background-color: #6206bf;
  height: 17px;
  width: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.poll_votes {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #81838d;
  margin-top: 5px;
  margin-bottom: 4px;
}
.poll_votes > span {
  color: "red"
}
.pollMessage {
  word-break: break-all;
}
