.heading {
  font-size: 1rem;
  font-weight: 600;
  line-height: 24px;
  color: #2d2f33;
}
.cancelButton {
  font-size: 1rem;
  font-weight: 600;
  line-height: 24px;
  color: #6206bf;
  cursor: pointer;
}
.drawerhandle {
  background-color: gray;
  height: 5px;
  width: 100%;
  border-radius: 20px;
  margin-bottom: 1rem;
}
