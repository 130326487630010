.labelContainer {
  margin: 0.5rem 0px;
}
.postComposerContainer {
  background-color: rgb(56, 163, 165, 0.1);
  border-radius: 20px;
  padding: 1rem;
  margin-top: 2rem;
}
.postComposerInnerContainer {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 1rem;
}
.optionName {
  font-weight: 500;
  font-size: 0.9rem;
}