.preview-container {
  display: flex;
  overflow-x: scroll;
  max-width: 450px;
  padding-right: 50px;
}
.preview-paper-container {
  overflow: hidden;
  height: 80px;
  width: 100px;
  margin: 0px 8px 8px 0px;
  border-radius: 8px;
}
.preview-media {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.remove-btn-container {
  position: absolute;
  top: 0px;
  right: 8px;
  cursor: pointer;
  background-color: #6206bf;
  border-radius: 50%;
  padding: 1px;
}
