body {
  margin: 0;
  font-family: "Causten Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efefef;
}

p {
  font-family: "Causten Regular", sans-serif;
  font-size: large;
  padding: 10;
  margin: 10;
}

h1 {
  font-family: "Causten SemiBold", sans-serif;
}
h2,
h3,
strong {
  font-family: "Causten SemiBold", sans-serif;
}

button.link {
  background-color: transparent;
  text-transform: none;
  border: none;
  font-size: large;
  font-family: "Causten Regular", sans-serif;
}

a,
.link {
  color: #6206bf;
  cursor: pointer;
}

a.underline {
  text-decoration: underline;
}

@font-face {
  font-family: "Causten Regular";
  src: local("Causten Regular"),
    url(./fonts/Causten-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Causten Bold";
  src: local("Causten Bold"), url(./fonts/Causten-Bold.otf) format("opentype");
}

@font-face {
  font-family: "Causten SemiBold";
  src: local("Causten Bold"), url(./fonts/Causten-SemiBold.otf) format("opentype");
}

@font-face {
  font-family: "Causten Medium";
  src: local("Causten Medium"),
    url(./fonts/Causten-Medium.otf) format("opentype");
}

.zealGradientButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  position: absolute;
  background: linear-gradient(96.15deg, #6206bf 35.32%, #38a3a5 114.8%);
  border-radius: 100px;
}

/*Prevent zoom in for input fields*/

@supports (-webkit-overflow-scrolling: touch) {
  input {
    font-size: 16px !important;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.load {
  width: 30px;
  height: 30px;
  margin: 10px 0px;
  border: solid 2px #6206bf;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
