.poll_votes {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #81838d;
  margin-top: 5px;
  margin-bottom: 4px;
}
.pollMessage {
  word-break: break-all;
}