.video-paper-container {
  border-radius: 10px;
  overflow: hidden;
  height: 112px;
  width: 143px;
}
.video-msg {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
