.sub-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #12121f;
}

.chat-body-container {
  position: relative;
  /* padding: 24px 16px; */
  border: 1px solid #6206bf !important;
  border-radius: 20px 20px 0px 0px !important;
  padding: 16px 24px 0px 24px !important;
  height: 55vh !important;
  overflow-y: hidden !important;
  cursor: pointer;
}
.footer-button-wrapper {
  position: sticky;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
  align-items: center;
  width: 100%;
  bottom: 0px;
}
.footer-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  text-align: center;

  color: #12121f;
}
.download-text {
  color: #6206bf !important;
}

@media only screen and (max-width: 600px) {
  .chat-body-container {
    height: 60svh !important;
  }
}
