.createPollContainer{
  padding-bottom: 50px;
}
.textfield {
  margin-bottom: 20px;
  width: 100%;
}

.PollTitleLabel {
  position: absolute;
  margin-top: 8px;
  margin-left: 10px;
  background-color: #ffffff;
  padding: 0px 5px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
}
.inputField {
  width: 100%;
  padding: 15px 10px;
  border: 1px solid #12121f;
  border-radius: 4px;
  font-size: 1rem;
  margin-top: 1rem;
  font-family: "Causten Regular";
}

.inputField:focus {
  outline: none;
  border-color: #12121f;
}

.heading {
  font-size: 1rem;
  font-weight: 600;
  line-height: 24px;
  color: #2d2f33;
}
.cancelButton {
  font-size: 1rem;
  font-weight: 600;
  line-height: 24px;
  color: #6206bf;
  cursor: pointer;
}

.container {
  border: 1px solid #12121f;
  width: 97%;
  border-radius: 5px;
  padding: 0px 10px;
  margin-top: 1rem;
  max-height: 300px;
  overflow-y: scroll;
}

.label {
  position: absolute;
  margin-top: -9px;
  background-color: #ffffff;
  padding: 0px 5px;
  font-size: 12px;
  font-weight: 600;
}

.inputRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.inputFieldContainer {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #12121f;
}

.inputRow input {
  flex: 1;
  width: 100%;
  display: block;
  margin-right: 5px;
  border: none;
  border-radius: 0px;
  /* margin-bottom: 1rem; */
  font-size: 16px;
  padding: 10px 0px;

  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
}
.inputRow input:focus {
  outline: none;
}

.addButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6206bf;
  padding: 5px 10px 10px 0px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}
.plusIcon {
  font-size: 2rem;
}
.removeButton {
  font-size: 2rem;
  /* background-color: aliceblue; */
  border-radius: 50%;
  padding: 5px;
  height: 10px;
  width: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 0.5rem;
  color: #6206bf;
}
