.backgroundGradient {
    min-height: 100vh;
    background: linear-gradient(130.42deg, #6206BF 18.85%, #38A3A5 103.31%);
    padding: 5vw 15vw 5vw 15vw;
    margin: 0;
}

.innerBox {
    border-radius: 15px;
    padding: 50px;
    background-color: #fff;
}

@media only screen and (max-width: 600px) {
    .backgroundGradient {
      padding: 10vh 0px 0px 0px !important;
      margin:0px;
      height: 100%;
      width: 100vw !important;
      background: linear-gradient(163.54deg, #6206BF 8.82%, #38A3A5 42.85%) !important;
    }
  
    .innerBox {
      min-height: 85vh;
      padding: 12px !important;
      margin: 0px;
    }
  
    .hideMobile {
      display: none;
    }
  
    .mobileOnly {
      display: inherit;
    }
    
    .logoImg {
      max-height: 100px;
    }
  }