.heading {
  font-family: "Causten Bold" !important;
  font-weight: 900;
  font-size: 40px !important;
  line-height: 54px;
  /* or 135% */
  text-align: center;
  letter-spacing: -0.012em;
  color: #12121f;
}

.message {
  font-family: "Causten Medium" !important;
  font-size: 16px !important;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #000000 !important;
}
.button {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 0px;
  /* identical to box height, or 125% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;

  /* M3/sys/light/on-primary */

  color: #ffffff;
}
.innerWhiteBox {
  min-height: 75vh;
  width: 60vw !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 20px;
}

@media only screen and (max-width: 600px) {
    .innerWhiteBox {
        min-height: 90vh;
        width: 100vw !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 20px 20px 0px 0px;
      }
}
