.files_wrapper {
  padding: 20px 0px;
}

.file_container {
  background-color: #f4f4f8;
  padding: 20px;
  border-radius: 12px;
}
.file_name {
  padding-left: 20px;
}
