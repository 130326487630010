.container {
  height: fit-content;
  width: 100%;
  margin-bottom: 16px;
}

.chat-info-container {
  padding-left: 16px;
}
.user-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #999999;
  display: flex;
  align-items: center;
}
.message-date {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #7c7c7c;
  display: flex;
  align-items: center;
}
.media-container {
  padding: 8px 0px;
}
.media-image {
  width: 143.43px;
  margin: 0px 8px 8px 0px;
  height: 112px;
  object-fit: cover;
  border-radius: 8px;
}
.chat-icons {
  padding-right: 4px;
}
.enableSendButton {
  background-color: #6206bf;
  transition: all 1s ease-out;
}
.enableSendButton:hover {
  scale: 1.1;
}

.reactions-container {
  width: 37px;
  height: 24px;
  background: #6206bf;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.reactions-count {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}
.reaction-icon {
  font-size: 12px;
  padding-right: 4px;
}
.avatar {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background-size: contain;
}
