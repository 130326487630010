.image-box-wrapper {
  overflow-x: scroll;
  max-width: 450px;
  padding-right: 50px;
}
.image-msg {
  width: 143.43px;
  margin: 0px 8px 8px 0px;
  height: 112px !important;
  object-fit: cover;
  border-radius: 8px;
}
