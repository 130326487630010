#circle-btn { 
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-container {
    position: relative;  
}

#cta-btn {
    border: 0;
    border-radius: 50px;
    color: white;
    background: #1976d2;
    padding: 15px 20px 16px 60px;
    text-transform: uppercase;
    background: linear-gradient(to right, #F8D247 50%, #1976d2 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition:all 2s ease;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: large;
}

#arrow-svg {
    background: #F8D247;
    padding: 11px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0%;
    margin-bottom: 4;
}

#cta-btn:hover {
    background-position: left bottom;
}