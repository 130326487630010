.domainText {
  position: relative;
}
.postComposerContainer {
  background-color: #deebff;
  border-radius: 20px;
  padding: 1rem;
  margin-top: 2rem;
}
.postComposerInnerContainer {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 0.5rem;
}
.zealProfile {
  width: 1.2rem;
  height: 1.2rem;
  /* border-radius: 50%; */
}
.optionName {
  font-weight: 500;
  font-size: 0.9rem;
}
.charCount {
  font-size: 0.8rem;
  margin-bottom: -0.5rem;
}