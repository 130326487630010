.titleBackground {
  background-color: #EDEDEE;
  padding: 0.4rem 1rem;
}
.memberRow {
  height: 60px;
  padding-left: 1rem;
  font-weight: 500;
}
.pillContainer {
  border: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 1rem;
}
