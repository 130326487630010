.chat-body-container1 {
  position: relative;
  border: 0px solid #6206bf;
  border-radius: 20px;
  padding: 0px 0px 10px 0px !important;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.loader-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.scroll-contianer {
  float: "left";
  clear: "both";
}

