.pill {
    border-radius: 50px;
    width: 42px;
    height: 42px;
    aspect-ratio: 1/1;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: lightgray solid 1px;
    margin-right: 10px;
    cursor: pointer;
    transition-duration: 0.2s;
}

.pill.active {
    background-color: #6206BF;
    color: #fff;
    border: none;
}

.pill.active:hover {
    background-color: #7432b8;
}

.pill.inactive {
    background-color: #fff;
    color: #535359;
}

.pill.inactive:hover {
    background-color: #eadcf7;
}
