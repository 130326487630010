.emojiContainer {
  padding: 5px;
  border: 1px solid white;
  margin: 2px;
  border-radius: 10px;
}
.emojiContainer:hover {
  padding: 5px;
  border: 1px solid #6206bf;
  cursor: pointer;
  background-color: rgba(98, 6, 191, 0.1);
}
.selectedEmojiContainer {
  padding: 5px;
  border: 1px solid white;
  margin: 2px;
  border-radius: 10px;
  border: 1px solid #6206bf;
  cursor: pointer;
  background-color: rgba(98, 6, 191, 0.1);
}
.addMoreButtonContainer {
  padding: 5px;
  border: 1px solid white;
  margin: 2px;
  border-radius: 10px;
  border: 1px solid #000000;
  cursor: pointer;
  background-color: #6206bf;
}
.interestLabel {
  font-size: 0.8rem;
}
