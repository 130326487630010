.image {
    top: 40px;
    width: 300px;
    height: auto;
    position: relative;
}

.downloadApp{
    display: none;
}

@media only screen and (max-width: 600px) {
    .image {
        margin-top: 0px;
        top: 5px;
    }

    .downloadApp{
        display: block;
    }
  }