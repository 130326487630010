/* Header.module.css */

.header {
  background-color: transparent;
  transition: all 0.5s ease, justify-content 0.5s ease-in-out; /* Add justify-content transition */
  position: relative;
  z-index: 1000;
  background: linear-gradient(119deg, #6206bf 32.77%, #38a3a5 92.35%);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.fixed {
  background-color: #fff;
  position: fixed;
  justify-content: flex-start;
  padding: 15px;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.zealLogo {
  width: 5rem;
}
.zealHorizontalLogo {
  width: 4.3rem;
  /* margin-bottom: 1rem; */
}

.headerTitle {
  color: #ffffff;
}
