.subheader {
    color: '#12121F';
    font-weight: 500;
    margin-top: 0;
}

#headerText {
    margin-bottom: 0;
}

#divider {
    background-color: black;
    height: 1px;
    width: 100%;
}