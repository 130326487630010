.linkThumbnailContainer {
  margin: 2% 0px;
  padding: 10px 0px;
  background-color: #e6e6ed;
  border-radius: 8px;
}
.linkName {
  color: #6206bf;
  font-weight: 600;
  font-size: 14px;
}

.linkDescription {
  font-size: 14px;
  padding-right: 10px;
  word-break: break-word;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #8b939b;
}
.link {
  color: #007aff;
  margin: 0.5rem 0px; 
}
.link:hover {
  color: #007aff;
  margin: 0.5rem 0px; 
  text-decoration: underlinebord;
}
.textBody {
    margin: '5px 0px';
}
.linkMetaContainer {
  padding: "0px 50px 0px 20px"
}
