.zealGradientContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1px;
    background: linear-gradient(96deg, #6206BF, #38A3A5);
    border-radius: 5px;
    max-height: fit-content;
    margin: 5px;
}