.composer-input-container {
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0 -10px 5px -2px rgba(255, 255, 255, 0.5);
  bottom: 0;
  background-color: #ffffff;
  padding-top: 0px;
  /* gap: 1rem; */
}
.composer-input-container {
  gap: 1rem;
}
.toggleOnContainer {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 32px !important;
  height: 32px !important;
  background-color: #6206bf;
  border-radius: 50%;
}
.toggleOffContainer {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 32px !important;
  height: 32px !important;
  background-color: #50555c;
  border-radius: 50%;
}
.toggleIcon {
  width: 15px !important;
}
.text-input {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d4d5de;
  height: 100%;
  border-radius: 15px;
  padding: 4px 5px 4px 10px;
  gap: 0.5rem
}
.text-input-inner {
  height: auto;
  width: 100%;
  border: none;
  padding: 5px;
  border: none;
  outline: none;
  font-size: 1rem;
  font-family: "Causten Regular", sans-serif;
  border: none;
  outline: none;
  resize: none;

}
.text-input-inner::-webkit-scrollbar {
  display: none;
}
.disableSendButton {
  height: 32px;
  width: 32px;
  background-color: #d3d3d4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.disableSendButton:hover {
  scale: 1.1;
}
.composer-input-container-inner {
  gap: 1rem;
}
