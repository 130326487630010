.primary-header-container {
    padding: 13px 0px; 
    z-index: 1;
    height: 80px;
}
.go-back-icon {
  padding-right: 20px;
  padding-top: 12px;
  cursor: pointer;
}
.group-name {
  font-weight: 700 !important;
  font-size: 25px !important;
  line-height: 24px !important;
  display: flex !important;
  align-items: center !important;
  color: #ffffff !important;
  text-transform: capitalize;
}
.group-members {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  display: flex;
  align-items: center;

  color: #ffffff;

  opacity: 0.7;
}
.footer-button {
    height: 75px;
}
.info-icon {
  height: 32px;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .primary-header-container {
    padding: 13px 12px; 
    /* position: fixed; */
    background-color: #6206BF;
    /* top: 0; */
    /* z-index: 1000 */
}


.group-name {
  font-size: 20px !important;
  line-height: 24px !important;
  display: flex !important;
  align-items: center !important;
  color: #ffffff !important;
  text-transform: capitalize;
}
.info-icon {
  height: 25px;
}
}