/* Initial styles */
.splashContainer {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(119deg, #6206bf 32.77%, #38a3a5 92.35%);
}

.zealLogo {
  width: 6rem;
  padding-bottom: 3rem;
  /* Initial scale value */
  transform: scale(0);
  /* Add animation properties */
  animation: logoScale 1s ease-out forwards 0s;
}
.progressBar {
  position: absolute;
  top: 10px;
  height: 10px;
  /* width: 300px; */
}
.title {
  position: fixed;
  bottom: 5rem;
  font-size: 1.5rem;
  color: #ffffff;
  /* Initial opacity value for fade-in effect */
  opacity: 0;
  /* Add animation properties */
  animation: titleFadeIn 2s ease-out forwards 1s;
}

/* Keyframes for the logo scale animation */
@keyframes logoScale {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

/* Keyframes for the title fade-in animation */
@keyframes titleFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
